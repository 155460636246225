import React from "react"
import Moment from 'moment'

export default ({ date }) => {

    return (
        <>
            { Moment(date).format('DD.MM.YYYY') }
        </>
    )
}