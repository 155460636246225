import React from 'react'
import Link from 'components/link'
import Button from 'components/button'
import Date from "../utils/date"

export default ({ post, index, postType }) => {
    const categoryKey = postType === 'resources' ? 'types' : 'categories'
    const fieldsKey = postType === 'resources' ? 'resourceFields' : 'postFields'

    const categoriesType = post[categoryKey]
    const categories = categoriesType ? categoriesType.nodes : ''

    const fields = post[fieldsKey]

    const background = post.featuredImage ? post.featuredImage.mediaItemUrl : ''

    const {
        link,
        title,
        date
    } = post

    const {
        excerpt
    } = post.postFields

    const {
        externalLink,
        fileOrLink,
        file
    } = post.resourceFields || {}

    const hasFile = fileOrLink === 'file' && file && file.mediaItemUrl

    const address = hasFile ? file.mediaItemUrl : externalLink || link

    return (
        <li key={ index }>
            <div className="meta-row">
                { categories &&
                    <div 
                        className="category" 
                        data-colour={categories[0].slug}
                        dangerouslySetInnerHTML={{ __html: categories[0].name }}
                    />
                }
                <div 
                    className="date"
                >
                    <Date date={date} />
                </div>
            </div>
            <Link 
                to={address}
                className={'image'}
            >
                <div style={{ backgroundImage: `url(${background})` }} />
            </Link>
            <div className="content">
                <Link to={address}>
                    <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
                </Link>
                { excerpt &&
                    <div className="copy" dangerouslySetInnerHTML={{ __html: excerpt}} />
                }
                { hasFile ?
                    <a className="download" href={file.mediaItemUrl} target="_blank">
                        <i className="fe fe-download" />
                        <span>
                            Download
                        </span>
                    </a>
                    : 
                    <Button
                        compact={true}
                        label={externalLink ? `Go to link` : `Read more`}
                        url={externalLink}
                    />
                }
            </div>
        </li>
    )
}
