import React, { useState, useContext, useRef, useEffect } from 'react'

import Link from 'components/link'
import { MainContext } from "context/context"

import { useStaticQuery, graphql } from "gatsby"

import Button from 'components/button'
import Slider from 'components/slider'

import 'vendor/flickity-as-nav-for/as-nav-for'

import { 
    formatDate,
    isMobile
} from 'utils'

import FadeCollapse from 'src/components/fadeCollapse'

const fade = typeof window !== "undefined" ? require("flickity-fade") : () => null

export default ({
    title,
    postsData,
    showDates,
    hideAllButton,
    isHero
}) => {
    const data = useStaticQuery(graphql`
        query homePostsQuery {
            siteFields {
                posts (
                    first: 4
                ) {
                    nodes {
                        databaseId
                        title
                        link
                        date
                        featuredImage {
                            sourceUrl
                        }
                        postFields {
                            excerpt
                            heroImage {
                                sourceUrl
                            }
                        }
                        resourceFields {
                            externalLink
                            fileOrLink
                            file {
                                mediaItemUrl
                            }
                        }
                        categories {
                            nodes {
                                id
                                slug
                                name
                            }
                        }
                    }
                }
                categories {
                    nodes {
                        name
                    }
                }
            }
        }
    `)

	const {
        addInvertedHeaderSection,
    } = useContext(MainContext)

    const elementRef = useRef(null)
    const foregroundSliderRef = useRef(null)
    const backgroundSliderRef = useRef(null)

    useEffect(() => {
        if(elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    useEffect(() => {
        if(foregroundSliderRef.current && backgroundSliderRef.current){
            foregroundSliderRef.current.on('change', index => {
                backgroundSliderRef.current.select(index, true)
            })
        }
    }, [foregroundSliderRef])

    const { nodes: posts } = postsData || data.siteFields ? data.siteFields.posts : {}

    const [isFeaturePostHovered, setIsFeaturePostHovered] = useState(false)

    if(!posts || !posts.length){
        return null
    }

    const firstPost = posts[0]

    const newsButton = hideAllButton ? null : (
        <Button
            label={'View all news'}
            url={'/news'}
        />
    )

    const getHeroPost = (post, showBg = true) => {
        const {
            title: _title,
            databaseId,
            link,
            featuredImage,
            date,
            postFields,
            categories: {
                nodes: categories
            },
            resourceFields
        } = post

        const categoryName = categories[0].slug !== 'uncategorised' ? categories[0].name : null

        const hasExternalLink = !!resourceFields.externalLink
        const _link = hasExternalLink ? resourceFields.externalLink : link

        return (
            <Link 
                to={_link} 
                className="post-feature"
                key={databaseId}
            >
                { showBg &&
                    <div className="bg" style={{
                        backgroundImage: `url(${ featuredImage ? featuredImage.sourceUrl : '' })`
                    }} />
                }
                <div className="content">
                    <div>
                        <p className="small meta" dangerouslySetInnerHTML={{ __html: categoryName ? categoryName+' • ' + formatDate(date) : '' + formatDate(date) }} />
                        <h3 dangerouslySetInnerHTML={{ __html: _title }}  />
                        <FadeCollapse open={isHero || isFeaturePostHovered}>
                            <div 
                                className={`copy small`} 
                                dangerouslySetInnerHTML={{ __html: postFields.excerpt }} 
                            />
                        </FadeCollapse>
                        <Button
                            inverted={true}
                            label={showBg ? undefined : hasExternalLink ? 'Go to link' : 'Read more'}
                        />
                    </div>
                </div>
            </Link>
        )
    }

    if(isHero){
        return (
            <div 
                className="latest-blog-hero"
                ref={ref => elementRef.current = ref}
            >
                <ul className="backgrounds">
                    <Slider
                        className={'background-slider'}
                        options={{
                            wrapAround: true,
                            prevNextButtons: false,
                            pageDots: false,
                            adaptiveHeight: false,
                            cellAlign: 'center',
                            fade: true
                        }}
                        flickityRef={ref => backgroundSliderRef.current = ref}
                    >
                        { posts.map((post, index) => {
                            const {
                                featuredImage,
                                postFields
                            } = post

                            const image = postFields.heroImage && postFields.heroImage.sourceUrl ? postFields.heroImage.sourceUrl : featuredImage ? featuredImage.sourceUrl : ''

                            return (
                                <li key={index}>
                                    <div style={{
                                        backgroundImage: `url(${ image })`
                                    }} />
                                </li> 
                            )
                        })}
                    </Slider>
                </ul>
                <div className="foreground container">
                    <ul>
                        <Slider
                            options={{
                                wrapAround: true,
                                prevNextButtons: true,
                                pageDots: true,
                                adaptiveHeight: true,
                                cellAlign: 'center',
                                autoPlay: 5000
                            }}
                            flickityRef={ref => foregroundSliderRef.current = ref}
                        >
                            { posts.map(post => {
                                return getHeroPost(post, false)
                            })}

                        </Slider>
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            { title &&
                <h2>
                    { title }
                    { !isMobile && newsButton }
                </h2>
            }
            <div className="latest-blog">
                <div 
                    className="left"
                    onMouseEnter={() => setIsFeaturePostHovered(true)}
                    onMouseLeave={() => setIsFeaturePostHovered(false)}
                >
                    { getHeroPost(firstPost, true) }
                </div>
                <div className="right">
                    { posts && posts.map((post, index) => {
                        if(!index){
                            return null
                        }

                        const {
                            featuredImage,
                            title,
                            postFields,
                            resourceFields,
                            date,
                            link
                        } = post

                        const hasExternalLink = !!resourceFields.externalLink
                        const _link = hasExternalLink ? resourceFields.externalLink : link

                        return (
                            <Link 
                                to={_link} 
                                key={index}
                            >
                                <img src={featuredImage ? featuredImage.sourceUrl : null} />
                                <div>
                                    <h3 dangerouslySetInnerHTML={{ __html: title }} />
                                    { showDates ?
                                        <span className="date">
                                            { formatDate(date) }
                                        </span>
                                    : null }
                                    <div className="copy small" dangerouslySetInnerHTML={{ __html: postFields.excerpt }} />
                                    <Button
                                        compact={true}
                                        label={hasExternalLink ? 'Go to link' : "Read more"}
                                        url={link}
                                        type="button"
                                    />
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
            { isMobile && 
                <div className="button-row">
                    { newsButton }
                </div>
            }
        </div>
    )
}