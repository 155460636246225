import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { isMobile } from 'utils'
import Select from 'react-select'
import { debounce } from 'lodash'


export default ({
    selectedSlug,
    handleChange,
    setSearchTerm: setFormattedSearchTerm,
    searchTerm: formattedSearchTerm,
    postType
}) => {
    const [displayedSelectedSlug, setDisplayedSelectedSlug] = useState(selectedSlug)
    const [searchTermToDisplay, setSearchTermToDisplay] = useState('')

    const categories = useStaticQuery(graphql`
        query categories {
            siteFields {
                types {
                    nodes {
                        slug
                        name
                    }
                }
                categories {
                    nodes {
                        slug
                        name
                    }
                }
                relatedProducts {
                    nodes {
                        slug
                        name
                    }
                }
            }
        }
    `)

    const categoryKey = (() => {
        if (postType === 'resources') return 'types'
        if (postType === 'stories') return 'relatedProducts'
        return 'categories'

    })()

    const cats = categories.siteFields[categoryKey].nodes

    const _categories = [{
        slug: '',
        name: postType === 'stories' ? 'Show All' : `All ${categoryKey}`
    }].concat(cats).filter(category => {
        return category.slug !== 'uncategorised' && category.slug !== 'product-sheets'
    })

    const _handleChange = slug => {
        handleChange(slug)

        setDisplayedSelectedSlug(slug)
    }

    const handleChangeMobileCategory = ({ value }) => {
        handleChange(value)

        setDisplayedSelectedSlug(value)
    }

    const selectedCategory = _categories.find(category => category.slug === selectedSlug)

    const selectedMobileCategory = {
        label: selectedCategory.name,
        value: selectedCategory.slug
    }

    const searchTermRef = useRef(searchTermToDisplay)
    searchTermRef.current = searchTermToDisplay

    const _handleChangeSearchTerm = term => {
        setFormattedSearchTerm(term || '')
    }

    const debouncedHandleChangeSearchTerm = useRef(debounce(_handleChangeSearchTerm, 300))

    const handlePostsSearch = e => {
        const { value } = e.target
        setSearchTermToDisplay(value)

        debouncedHandleChangeSearchTerm.current(value)
    }

    return (
        <div className="filter">
            <ul className="container">
                {!isMobile ?
                    _categories.map((row, index) => (
                        <li
                            data-name={row.slug}
                            key={index}
                            className={row.slug === displayedSelectedSlug ? 'active' : ''}
                            onClick={() => _handleChange(row.slug)}
                            dangerouslySetInnerHTML={{ __html: row.name }}
                        />
                    ))
                    :
                    <Select
                        options={_categories.map(category => {
                            return {
                                label: category.name.replace('&amp;', '&'),
                                value: category.slug
                            }
                        })}
                        value={selectedMobileCategory}
                        className="select"
                        onChange={handleChangeMobileCategory}
                    />
                }
                <form
                    onSubmit={e => e.preventDefault()}
                    className={'search'}
                >
                    <i className={'fa fa-search'} />
                    <input
                        value={searchTermToDisplay}
                        onChange={handlePostsSearch}
                        placeholder="Search..."
                    />
                </form>
            </ul>
        </div>
    )
}
